/* This is all a bit funky but it styles it to use our colors and then also makes it smaller to fit mobile better */

.rdp-root {
	--rdp-accent-color: hsl(var(--nextui-primary));
	--rdp-accent-background-color: hsl(var(--nextui-primary-100));

	--rdp-day-height: 30px;
	--rdp-day_button-height: 28px;
	--rdp-day-width: 30px;
	--rdp-day_button-width: 28px;

	font-size: var(--nextui-font-size-small);
}

.rdp-selected {
	font-size: var(--nextui-font-size-medium);
}

@screen sm {
	.rdp-root {
		--rdp-day-height: 44px;
		--rdp-day_button-height: 42px;
		--rdp-day-width: 44px;
		--rdp-day_button-width: 42px;

		font-size: var(--nextui-font-size-medium);
	}

	.rdp-selected {
		font-size: var(--nextui-font-size-large);
	}
}
